import React from 'react';
import { Link } from 'react-router-dom';

class NotificationButtons extends React.Component {
  render() {
    return (
      <div className="buttons">
        <Link to="/addNotification">
          <button
            className="defaultButton" >
            Lisää ilmoitus
          </button>
        </Link>
        <button
          className="defaultButton"
          onClick={this.props.onPastNotisPressed}>
          {this.props.pastNotisShown ? "Näytä vain tulevat" : "Näytä kaikki"}
        </button>
      </div>
    );
  }
}

export default NotificationButtons;
