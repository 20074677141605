import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import createHistory from "history/createBrowserHistory";
import createRoutes from "./routes";

const historyBaseName = process.env.REACT_APP_HISTORY_BASE_NAME || "";
console.log("basename:", historyBaseName);

const history = createHistory({ basename: historyBaseName });
const routes = createRoutes();

ReactDOM.render(
  <App routes={routes} history={history} />,
  document.getElementById("root")
);
