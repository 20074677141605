import React from 'react';
import NotificationItem from './NotificationItem';

class NotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.removeNotification = this.removeNotification.bind(this);
    this.handleRemovePress = this.handleRemovePress.bind(this);
  }

  handleRemovePress(id) {
    this.removeNotification(id);
  }

  removeNotification(id) {
    let token = sessionStorage.getItem('jwt');
    fetch('/api/removeNotification', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then(res => res.json())
      .then(res => {
        if (res.message === 'ok') {
          alert('Notification was removed successfully!');
          this.props.updateList(false);
        } else {
          alert(res.message);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  parseNotifications(notifications) {
    return Object.keys(notifications).map((notification, idx) => {
      return (
        <NotificationItem
          notification={notifications[notification]}
          onRemoveButtonPressed={this.handleRemovePress}
          idx={idx}
          key={idx}
        />
      );
    });
  }

  render() {
    const notifications = this.props.notifications
      ? this.props.notifications
      : [];
    const parsedNotifications = this.parseNotifications(notifications);

    return (
      <div className="notificationList">
        <ul>{parsedNotifications}</ul>
      </div>
    );
  }
}

export default NotificationList;
