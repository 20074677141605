import React from 'react';

class Login extends React.Component {
  static isPrivate = false;

  constructor(props) {
    super(props);
    this.state = { name: '', password: '', loginSuccess: false };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.name && this.state.password) {
      this.checkLoginData(this.state.name, this.state.password);
    } else {
      alert('Anna sekä käyttäjätunnus että salasana.');
    }
  }

  checkLoginData(username, password) {
    fetch('/api/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: username,
        password: password,
      }),
    })
      .then(res => res.json())
      .then(res => {
        if (res.token) {
          this.setState({ loginSuccess: true }, function() {
            sessionStorage.setItem('jwt', res.token);
            this.props.history.replace('/home');
          });
        } else {
          alert(res.message);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    return (
      <div className="defaultFormContent">
        <form className="defaultForm" onSubmit={this.handleSubmit}>
          <label className="nameLabel">
            Käyttäjätunnus:
            <input type="text" name="name" onChange={this.handleNameChange} />
          </label>
          <label className="passwordLabel">
            Salasana:
            <input
              type="password"
              name="password"
              onChange={this.handlePasswordChange}
            />
          </label>
          <input className="defaultSubmitButton" type="submit" value="Kirjaudu sisään" />
        </form>
      </div>
    );
  }
}

export default Login;
