import React from 'react';
import './styles.css';

class NotificationItem extends React.Component {
  handleRemovePress = () => {
    this.props.onRemoveButtonPressed(this.props.notification.id);
  };

  render() {
    return (
      <li key={this.props.idx}>
        <div className="calendarItem">
          <div className="content">
            <div className="data">
              <p className="description">
                {this.props.notification.description}
              </p>
              {
                (this.props.notification.start_time) &&
                <p className="expiry">
                  Alkaa: {this.props.notification.start_time.replace('T', ' ')}
                </p> 
              }
              <p className="expiry">
                Päättyy: {this.props.notification.end_time.replace('T', ' ')}
              </p>
            </div>
            <img
              src={require('./images/remove-icon.png')}
              alt=""
              className="removeButton"
              onClick={this.handleRemovePress}
            />
          </div>
        </div>
      </li>
    );
  }
}

export default NotificationItem;
