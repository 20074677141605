import React from 'react';

class AddNotification extends React.Component {
  static isPrivate = true;

  constructor(props) {
    super(props);
    this.state = { description: '', startTime: '', endTime: '' };
    this.handleDescChange = this.handleDescChange.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const local = new Date().toDateInputValue();
    this.setState({ startTime: local })
  }

  handleDescChange(event) {
    this.setState({ description: event.target.value });
  }

  handleStartTimeChange(event) {
    this.setState({ startTime: event.target.value });
  }

  handleEndTimeChange(event) {
    this.setState({ endTime: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    let description = this.state.description.trim();
    let startTime = this.state.startTime;
    let endTime = this.state.endTime;
    if (!description || !endTime) {
      alert('Please fill all of the fields.');
      return;
    }

    this.submitNotification({ description: description, startTime: startTime, endTime: endTime });
  }

  submitNotification(notification) {
    let token = sessionStorage.getItem('jwt');
    if (!token) return;

    fetch('/api/addNotification', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        description: notification.description,
        start_time: notification.startTime,
        end_time: notification.endTime,
      }),
    })
      .then(res => res.json())
      .then(res => {
        if (res.message === 'ok') {
          alert('Notification was submitted successfully!');
          this.setState({
            description: '',
            startTime: '',
            endTime: '',
          });
          this.props.history.replace('/home');
        } else {
          alert(res.message);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    
    return (
      <div className="defaultFormContent">
        <form className="defaultForm" onSubmit={this.handleSubmit}>
          <label className="descLabel">
            Kuvaus:
            <textarea name="desc" maxLength="512" onChange={this.handleDescChange}></textarea>
          </label>
          <label className="startTimeLabel">
            Alkaa:
            <input
              type="datetime-local"
              maxLength="20"
              name="startTime"
              value={this.state.startTime}
              onChange={this.handleStartTimeChange}
            />
          </label>
          <label className="endTimeLabel">
            Päättyy:
            <input
              type="datetime-local"
              maxLength="20"
              name="endTime"
              onChange={this.handleEndTimeChange}
            />
          </label>
          <input className="defaultSubmitButton" type="submit" value="Submit" />
        </form>
      </div>
    );
  }
}

Date.prototype.toDateInputValue = (function() {
    var local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0,16);
});

export default AddNotification;
