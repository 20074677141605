import React from 'react';
import NotificationList from './NotificationList';
import NotificationButtons from './NotificationButtons';

class Home extends React.Component {
  static isPrivate = true;

  constructor(props) {
    super(props);
    this.state = { notifications: {}, pastNotisShown: false };
    this.fetchNotifications = this.fetchNotifications.bind(this);
    this.handlePastNotisPress = this.handlePastNotisPress.bind(this);
  }

  handlePastNotisPress() {
    this.fetchNotifications(!this.state.pastNotisShown);
  }

  componentDidMount() {
    this.fetchNotifications(this.state.pastNotisShown);
  }

  fetchNotifications(all) {
    let token = sessionStorage.getItem('jwt');
    let config = {};

    if (token) {
      config = { headers: { Authorization: `JWT ${token}` } };
    } else {
      console.log('No token saved!');
      return;
    }

    fetch('/api/notifications', config)
      .then(res => res.json())
      .then(notis => {
        this.setState({ 
          notifications: this.parsedNotifications(notis, all),
          pastNotisShown: all
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  parsedNotifications(notifications, all) {
    const timeNow = new Date();
    var upcomingNotifications = [];

    for(let i = 0; i < notifications.length; i++) {
      var notiExpiry = new Date(notifications[i].end_time);
      if(all || timeNow < notiExpiry) {
        upcomingNotifications.push(notifications[i]);
      }
    }
    upcomingNotifications.sort(function(a, b) {
      a = new Date(a.end_time);
      b = new Date(b.end_time);
      return a<b ? -1 : a>b ? 1 : 0;
    });
    return upcomingNotifications;
  }

  render() {
    return (
      <div>
        <NotificationButtons 
          onPastNotisPressed={this.handlePastNotisPress}
          pastNotisShown={this.state.pastNotisShown} />
        <NotificationList
          notifications={this.state.notifications}
          updateList={() => this.fetchNotifications(this.state.pastNotisShown)} />
      </div>
    );
  }
}

export default Home;
