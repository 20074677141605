import React from 'react';
import Toolbar from '../Toolbar';
import '../styles.css';

class ViewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loggedin: false };
  }

  render() {
    return (
      <div className="main-container">
        <Toolbar loggedin={this.state.loggedin} />
        <div className="content-container">{this.props.children}</div>
      </div>
    );
  }
}

export default ViewContainer;
