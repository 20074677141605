import React from 'react';
import { Switch, Redirect } from 'react-router';
import Route from '../components/AuthRoute';

import Home from '../components/Home';
import Login from '../components/Login';
import AddNotification from '../components/AddNotification';

function createRoutes() {
  return (
    <Switch>
      <Redirect from="/" exact to="/login" />
      <Route component={Login} path="/login" />
      <Route component={Home} path="/home" />
      <Route component={AddNotification} path="/addNotification" />
    </Switch>
  );
}

export default createRoutes;
