import React from 'react';
import { Link } from 'react-router-dom';

class Toolbar extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    sessionStorage.removeItem('jwt');
  }

  render() {
    return (
      <div className="toolbar">
        <div className="toolbar-content">
          <Link to="/home">
            <img
              src={require('./images/bussitunnus.png')}
              alt=""
              className="menu-button"
            />
          </Link>

          <p style={{paddingTop: '7px', paddingLeft: '20px', color: 'white', position:'relative', display:'inline-block'}}>Lpr bussit - ilmoitukset</p>

          {sessionStorage.getItem('jwt') && (
            <Link
              to="/login"
              className="logout-link"
              onClick={this.handleLogout}>
              <div>
                <p className="logout-text">Logout</p>
              </div>
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default Toolbar;
