import React, { Component } from 'react';
import { Router } from 'react-router';
import ViewContainer from './components/ViewContainer';
import './App.css';

class App extends Component {
  render() {
    return (
      <Router routes={this.props.routes} history={this.props.history}>
        <ViewContainer>{this.props.routes}</ViewContainer>
      </Router>
    );
  }
}

export default App;
